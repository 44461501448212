import React from 'react'

import { Box, Flex } from '@rhythm/components'

import NarrativeCard from '../NarrativeCard'

import DeviceBatteryNotes from './DeviceBatteryNotes'
import DeviceBatteryTable from './DeviceBatteryTable'

const DeviceBatteryCard = ({
  isInClinic,
}: {
  isInClinic: boolean
}): React.ReactElement => {
  return (
    <NarrativeCard title="Battery Status" icon="battery">
      <Flex direction={'row'} gap="4xl">
        {!isInClinic && (
          <Box flex={1}>
            <DeviceBatteryNotes />
          </Box>
        )}
        <Box flex={1}>
          <DeviceBatteryTable />
        </Box>
      </Flex>
    </NarrativeCard>
  )
}

export default DeviceBatteryCard
