import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
} from '@rhythm/components'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useMutation } from 'react-query'
import * as yup from 'yup'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerUpdateRequest,
  queryClient,
  UpdateTransmissionReportParams,
} from '../../../../lib/api'
import { useTransmissionReportContext } from '../../context'

type FormType = {
  presentingRhythm?: string
  underlyingRhythm?: string
  presentingRate?: number
  underlyingRate?: number
}

const schema = yup.object({
  presentingRhythm: yup
    .string()
    .nullable()
    .max(500, 'Maximum of 500 characters allowed.')
    .optional(),
  underlyingRhythm: yup
    .string()
    .nullable()
    .max(500, 'Maximum of 500 characters allowed.')
    .optional(),
  presentingRate: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Transform empty string to null
    .nullable()
    .typeError('Presenting Rate must be a number.')
    .test(
      'len',
      'Presenting Rate must be an integer.',
      (val: unknown) => String(val).length <= 9,
    )
    .optional(),

  underlyingRate: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value)) // Transform empty string to null
    .nullable()
    .typeError('Underlying Rate must be a number.')
    .test(
      'len',
      'Underlying Rate must be an integer.',
      (val: unknown) => String(val).length <= 9,
    )
    .optional(),
})

const TransmissionReportPresentingFields = ({
  transmissionReportId,
}: {
  transmissionReportId: number
}): React.ReactElement => {
  const { transmissionReport } = useTransmissionReportContext()
  const Api = useApiContext()

  // State to track if the form has been interacted with.
  const [hasInteracted, setHasInteracted] = useState(false)

  const methods = useForm<FormType>({
    defaultValues: (transmissionReport?.presentingCard ?? {}) as FormType,
    mode: 'onBlur', // Validation triggers on onBlur
    resolver: yupResolver(schema),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods

  // Watch all the form values for changes.
  const formValues = useWatch({ control })

  const { mutateAsync: updatePresentingData } = useMutation({
    mutationFn: async (
      updateTransmissionReportParams: DefaultApiTransmissionReportsControllerUpdateRequest,
    ) => {
      await Api.transmissionReportsControllerUpdate(
        updateTransmissionReportParams,
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        'transmissionReport',
        transmissionReport?.id,
      ])
    },
  })

  const onSubmit = async (data: FormType) => {
    // Handling the submit by calling the mutation with the form data.
    await updatePresentingData({
      transmissionReportId,
      updateTransmissionReportParams: data as UpdateTransmissionReportParams,
    })
  }

  // Trigger API call whenever formValues changes
  useEffect(() => {
    if (hasInteracted) {
      // Adds a debounced of 500ms to prevent rapid saves
      const debounce = setTimeout(() => {
        handleSubmit(onSubmit)()
      }, 500)

      // Clear timeout if values change rapidly.
      return () => clearTimeout(debounce)
    }
  }, [formValues, handleSubmit, hasInteracted])

  const handleInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onChange={handleInteraction}>
        <Flex direction="column" rowGap={4}>
          <SimpleGrid columns={2} gap="xl">
            <FormControl>
              <FormLabel>Presenting Rhythm</FormLabel>
              <Input {...methods.register('presentingRhythm')} />
              {errors?.presentingRhythm && (
                <Text mt={1} color={'red.400'}>
                  {errors?.presentingRhythm?.message}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Presenting Rate</FormLabel>
              <Input {...methods.register('presentingRate')} />
              {errors?.presentingRate && (
                <Text mt={1} color={'red.400'}>
                  {errors?.presentingRate?.message}
                </Text>
              )}
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={2} gap="xl">
            <FormControl>
              <FormLabel>Underlying Rhythm</FormLabel>
              <Input {...methods.register('underlyingRhythm')} />
              {errors?.underlyingRhythm && (
                <Text mt={1} color={'red.400'}>
                  {errors?.underlyingRhythm?.message}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Underlying Rate</FormLabel>
              <Input {...methods.register('underlyingRate')} />
              {errors?.underlyingRate && (
                <Text mt={1} color={'red.400'}>
                  {errors?.underlyingRate?.message}
                </Text>
              )}
            </FormControl>
          </SimpleGrid>
        </Flex>
      </form>
    </FormProvider>
  )
}

export default TransmissionReportPresentingFields
