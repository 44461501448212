import React from 'react'

import { ReportFilterOption, ReportFilterPanel } from './ReportFilterPanel'

const filterOptions: ReportFilterOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Remote',
    value: 'remote',
  },
  {
    label: 'Remote In-clinic',
    value: 'remote-in-clinic',
  },
  {
    label: 'Manual In-clinic',
    value: 'in-clinic',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
]

export const FilterByReportType = ({
  onMenuItemClick,
}: {
  onMenuItemClick: (filter: ReportFilterOption) => void
}): React.ReactElement => {
  return (
    <ReportFilterPanel
      onMenuItemClick={onMenuItemClick}
      filterOptions={filterOptions}
      title="Report Type:"
    />
  )
}
