import React from 'react'

import { Flex, HStack, VStack } from '@rhythm/components'

import NarrativeCard from '../NarrativeCard'

import DeviceMonitoredValuesTable from './DeviceMonitoredValuesTable'
import DeviceProgrammedParametersTable from './DeviceProgrammedParametersTable'

const DeviceProgrammedParamsAndMonitoredValuesCard = (): React.ReactElement => {
  return (
    <NarrativeCard
      icon="monitored-values"
      title="Programmed Parameters/ Monitored Values"
    >
      <VStack alignItems="stretch">
        <HStack alignItems="top" spacing="4xl">
          <Flex width="40%" direction="column" alignItems="stretch">
            <DeviceProgrammedParametersTable />
          </Flex>
          <Flex width="60%" direction="column" alignItems="stretch">
            <DeviceMonitoredValuesTable />
          </Flex>
        </HStack>
      </VStack>
    </NarrativeCard>
  )
}

export default DeviceProgrammedParamsAndMonitoredValuesCard
