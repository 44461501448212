import { ChangeEvent, memo, useEffect, useRef, useState } from 'react'

import {
  Box,
  Divider,
  Error,
  Heading,
  HStack,
  IconButton,
  Image,
  Spinner,
  Tooltip,
} from '@rhythm/components'

import { ExtendedTransmissionReportImage } from '../../hooks'

import { ImagePreview } from './components/ImagePreview'
import { ImageThumbnail } from './components/ImageThumbnail'

export interface ImageUploadProps {
  images?: ExtendedTransmissionReportImage[]
  imagesWithUrl?: string
  cardTitle?: string
  egmImageOverideUrl?: string
  max?: number
  isContentExpanded: boolean
  expandedIndex: number
  onToggleExpanded: (index: number, toggle?: boolean) => void
  onImageUpload: (image: File) => Promise<boolean>
  onImageDelete: (image: ExtendedTransmissionReportImage) => Promise<boolean>
  onEgmImageDelete: (value: any) => void
  canUpload?: boolean
  isDeletingImage: boolean
  isInClinicReport: boolean
}

export const TransmissionReportAlertImages = memo(
  ({
    images = [],
    max = 3,
    onToggleExpanded,
    onImageUpload,
    onImageDelete,
    onEgmImageDelete,
    imagesWithUrl,
    cardTitle,
    egmImageOverideUrl,
    canUpload = true,
    expandedIndex,
    isContentExpanded,
    isDeletingImage,
    isInClinicReport,
  }: ImageUploadProps) => {
    const [activeImgIndex, setActiveImgIndex] = useState<number>(-1)
    const [isUploading, setIsUploading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const isAlertCardPresenting = cardTitle === 'Presenting'
    const handleImageDelete = async (
      image: ExtendedTransmissionReportImage,
    ) => {
      setActiveImgIndex(activeImgIndex === 0 ? 0 : activeImgIndex - 1)
      setIsUploading(true)
      await onImageDelete(image)
      setIsUploading(false)
    }

    const handleEgmImageDelete = (value: any) => {
      onEgmImageDelete && onEgmImageDelete(value)
    }

    const urlInfo: any = imagesWithUrl
    const egmUrlInfo =
      egmImageOverideUrl !== '' ? egmImageOverideUrl : undefined

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      const {
        target: { files },
      } = e
      setIsUploading(true)
      setErrorMessage(null) // Clear any previous error message

      if (files && files.length > 0) {
        for (const file of Array.from(files)) {
          if (!file.type.startsWith('image/')) {
            setErrorMessage('Only image files are supported.')
            setIsUploading(false)
            return
          }
          await onImageUpload(file) // Await each image upload
        }
      }

      setIsUploading(false)
    }

    const handlePasteImageFromClipboard = async () => {
      try {
        setIsUploading(true)
        const clipboardItems = await navigator.clipboard.read()
        console.log(clipboardItems)
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            if (type.startsWith('image/')) {
              const blob = await clipboardItem.getType(type)
              const file = new File([blob], 'pasted-image.png', {
                type: blob.type,
              })
              // We're assuming here that the first image we find is latest but clipboard doesn't inherently tell which image is latest.
              //Letting this be because the product ask was to paste one image at a time, ideally we can loop over all the images in the clipboard
              await onImageUpload(file)
              setIsUploading(false)
              return
            }
          }
        }
        setIsUploading(false)
      } catch (error) {
        console.error('Error pasting image from clipboard:', error)
        setIsUploading(false)
      }
    }

    const [showUrlInfo, setShowUrlInfo] = useState(true)
    const showImages = images && images.length > 0
    useEffect(() => {
      const showUrlInfoValues = isAlertCardPresenting && urlInfo && egmUrlInfo
      setShowUrlInfo(showUrlInfoValues)
    }, [cardTitle, urlInfo, egmUrlInfo])

    const isUploadDisabled =
      !canUpload ||
      (images.length > 1 && urlInfo && isAlertCardPresenting) ||
      images.length > 2

    return (
      <Box>
        {isAlertCardPresenting &&
          !urlInfo &&
          !images?.length &&
          !isUploading &&
          !errorMessage &&
          !isInClinicReport && (
            <Error message="Presenting image not available" color="red" />
          )}

        {errorMessage && <Error message={errorMessage} color="red" />}

        <Box>
          {!isUploading ? (
            <Tooltip
              label={
                !canUpload
                  ? 'Please give a name to the alert card to start uploading images'
                  : undefined
              }
              position="sticky"
            >
              <HStack
                spacing="md"
                alignItems="center"
                justifyContent="center"
                borderWidth="1px"
                borderRadius="md"
                p="lg"
              >
                <Heading fontSize="sm" color="gray.600" lineHeight={1.5}>
                  Upload image here
                </Heading>
                <IconButton
                  aria-label="Upload"
                  icon={'upload'}
                  onClick={() => inputRef?.current?.click()}
                  disabled={isUploadDisabled}
                  variant="plain"
                  height="xl"
                  width="xl"
                  mt="md"
                  p="xl"
                  color="primary.500"
                  borderColor="neutral.600"
                  borderRadius="md"
                  borderWidth="x1"
                >
                  Upload image here
                </IconButton>
                <Divider orientation="vertical" />
                <Heading fontSize="sm" color="gray.600">
                  or
                </Heading>
                <Divider orientation="vertical" />
                <Heading fontSize="sm" color="gray.600" lineHeight={1.5}>
                  Click on icon to paste
                </Heading>
                <IconButton
                  aria-label="Paste"
                  icon={'file'}
                  onClick={handlePasteImageFromClipboard}
                  variant="plain"
                  height="xl"
                  width="xl"
                  mt="md"
                  p="xl"
                  color="primary.500"
                  borderColor="neutral.600"
                  borderRadius="md"
                  borderWidth="x1"
                  disabled={isUploadDisabled}
                >
                  Click on Icon to paste
                </IconButton>
              </HStack>
            </Tooltip>
          ) : (
            <HStack
              spacing="md"
              alignItems="center"
              justifyContent="center"
              p="lg"
            >
              <Box alignContent={'center'}>
                <Spinner alignSelf={'center'} />
              </Box>
            </HStack>
          )}
          <HStack
            spacing="xl"
            mt="xl"
            alignContent="space-evenly"
            overflow={'auto'}
          >
            {urlInfo && egmUrlInfo && showUrlInfo && (
              <ImagePreview
                onDelete={() => handleEgmImageDelete('remove_egmimage')}
                onToggleExpanded={() => onToggleExpanded(-1)}
                expandedIndex={expandedIndex}
                isOpen={isContentExpanded}
              >
                <Image minW={'2xl'} src={urlInfo} alt="EGM Image" />
              </ImagePreview>
            )}

            {!showUrlInfo && showImages && images[expandedIndex] && (
              <ImagePreview
                onDelete={() => handleImageDelete(images[expandedIndex])}
                onToggleExpanded={() => onToggleExpanded(expandedIndex)}
                expandedIndex={expandedIndex}
                isOpen={isContentExpanded}
              >
                <Image
                  minW={'2xl'}
                  src={images[expandedIndex]?.url}
                  alt="Alert card supplemental image"
                />
              </ImagePreview>
            )}
          </HStack>
          <HStack spacing="xl" mt="xl" alignContent="space-evenly">
            {urlInfo && egmUrlInfo && isAlertCardPresenting && (
              <ImageThumbnail
                isActive={showUrlInfo}
                onClick={() => {
                  onToggleExpanded(-1, false)
                  setShowUrlInfo(true)
                }}
              >
                <Image src={urlInfo} alt="EGM Image" />
              </ImageThumbnail>
            )}

            {!isDeletingImage &&
              images.map((image, index) => (
                <ImageThumbnail
                  isActive={expandedIndex === index}
                  key={image.url}
                  onClick={() => {
                    onToggleExpanded(index, false)
                    setShowUrlInfo(false)
                  }}
                >
                  <Image
                    src={image.url}
                    alt="Alert card supplemental thumbnail"
                  />
                </ImageThumbnail>
              ))}
          </HStack>
        </Box>

        <input
          type="file"
          hidden
          accept="image/*"
          ref={inputRef}
          multiple
          onChange={handleInputChange}
        />
      </Box>
    )
  },
)
TransmissionReportAlertImages.displayName = 'TransmissionReportAlertImages'
