import { useMemo, useState } from 'react'
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'

import {
  Box,
  Circle,
  Flex,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@rhythm/components'
import moment from 'moment'

import DeviceHistoryModal from '../../../components/DeviceHistoryModal'
import FormLoading from '../../../components/FormLoading'
import { useFeatureFlagsContext } from '../../../context/FeatureFlagsContext'
import { useOrganization } from '../../../features/organizations/api/getOrganization'
import { useGetPatient } from '../../../features/patients'
import { useGetAllNextScheduledTransmissions } from '../../../features/scheduledtransmissions/api'
import { useGetICDDiagnosisCodes } from '../../../features/transmissionReports/api/getICDDiagnosisCodes'
import { TransmissionReportSignatureStatusEnum } from '../../../lib/api'
import { routes } from '../../../routes'
import { PatientTransmissionReport, TramssionReportColor } from '../../../types'
import { REASONS_FOR_MONITORING_OPTIONS } from '../../../utils/constants/transmissionReports'
import { ViewReportListModal } from '../TransmissionReportPage/components/ViewReportModal'
import ViewPDF from '../TransmissionReportPage/components/ViewReportModal/ViewPDF'

import ClinicalNotes from './components/ClinicalNotes/ClinicalNotes'
import { PinnedNotesCard } from './components/ClinicalNotes/PinnedNotesCard'
import DeviceDetailsWithTransmissionSchedule from './components/DeviceDetailsWithTransmissionSchedule'
import { getTermDaysFromOrgBasedOnDeviceType } from './components/DeviceDetailsWithTransmissionSchedule/constants'
import EditMedicalDetailsModal from './components/EditMedicalDetailsModal/EditMedicalDetailsModal'
import EditPatientDetailsModal from './components/EditPatientDetailsModal'
import MedicalDetailsCard from './components/MedicalDetailsCard'
import PatientDetailsCard from './components/PatientDetailsCard'
import ReportsList from './components/ReportsList'
import UpcomingScheduleCard from './components/UpcomingScheduleCard'

const PatientProfilePage = () => {
  const { id: patientId } = useParams<{ id: string }>()
  const { data: icdDianosisCodes } = useGetICDDiagnosisCodes()
  const [tabIndex, setTabIndex] = useState<number>(0)
  const {
    data: patient,
    isLoading: patientIsLoading,
    refetch: refetchPatient,
  } = useGetPatient({
    patientId,
  })
  const { hasFeatureFlag, featureFlags } = useFeatureFlagsContext()
  const isClinicalNotesEnabled = hasFeatureFlag('clinicalNotes')
  let { data } = useGetAllNextScheduledTransmissions({
    patientId,
  })

  if (!data) {
    data = {
      patientId: '',
      nextScheduleTranmission: [],
      scheduleTransmissionReadOnly: true,
    }
  }

  const { nextScheduleTranmission, scheduleTransmissionReadOnly } = data

  const showErrorFlag = useMemo(() => {
    if (scheduleTransmissionReadOnly) return false

    return (
      patient?.matchDevices?.some(device => {
        const scheduledTransmissions = device?.scheduleTransmissions
        const lastScheduledDate =
          scheduledTransmissions?.[scheduledTransmissions.length - 1]
            ?.scheduledDate
        return (
          device.scheduleRequiresCorrection ||
          !scheduledTransmissions ||
          scheduledTransmissions.length === 0 ||
          moment.utc(lastScheduledDate).isBefore(moment.utc(), 'days') ||
          scheduledTransmissions.some(
            transmission => transmission.transmissionStatus === 'missed',
          )
        )
      }) ?? false
    )
  }, [patient?.matchDevices])

  const isExistMonitorReason = REASONS_FOR_MONITORING_OPTIONS.find(
    e => patient && e.value === patient.monitorReason,
  )
  if (patient && !isExistMonitorReason) {
    patient.monitorReason = '-'
  }

  let rhythmidd: string
  if (patient?.rhythmId) {
    rhythmidd = patient?.rhythmId
  }
  const { data: orgData, isLoading: isLoadingOrgData } = useOrganization({
    organizationId: patient?.clinic?.parentId ?? '',
  })
  const ehrIntegration: boolean = orgData?.organization.ehrIntegration ?? false
  const isManagedInRS: boolean = orgData?.organization.isManagedInRS ?? false

  const [vendorTransmissionId, setVendorTransmissionId] = useState<
    string | null
  >(null)

  const [signedReportId, setSignedReportId] = useState<string | null>(null)
  const [zeroSign, setZeroSign] = useState<boolean>(false)

  const history = useHistory()

  const location = useLocation()

  const [editPatientDetailModalIsOpen, setEditPatientDetailModalIsOpen] =
    useState(false)
  const [editMedicalDetailModalIsOpen, setEditMedicalDetailModalIsOpen] =
    useState(false)

  const [deviceHistoryModalIsOpen, setDeviceHistoryModalIsOpen] =
    useState(false)

  const patientClone: any = { ...patient }
  const transmissionReports: PatientTransmissionReport[] =
    patientClone?.transmissionReports ? patientClone?.transmissionReports : []

  const vtreports = patientClone?.vtTransmissionReports

  vtreports?.forEach((e: { id: string; vtTransmisisonDate: any }) => {
    transmissionReports.forEach((report: any) => {
      if (e.id === report.transmissionId) {
        e.vtTransmisisonDate = report.transmissionDate
      }
    })
  })

  transmissionReports.forEach(e => {
    const vtreports = patientClone?.vtTransmissionReports
    vtreports.forEach((e: any) => {
      transmissionReports.forEach((report: any) => {
        if (e.transmissionId === report.id) {
          report.vtTransmisisonDate = e.transmissionDate
        }
      })
    })
    e.isPhysicianSigned = !(
      e.signatureStatus === TransmissionReportSignatureStatusEnum.NotSigned ||
      e.signatureStatus ===
        TransmissionReportSignatureStatusEnum.NonPractitionerSigned
    )
    e.fileName = generateFileName(e, e.matchDevice?.type || '')
  })
  transmissionReports.sort(
    (a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf(),
  )
  const onDownload = (report: PatientTransmissionReport) => {
    if (
      report.signatureStatus === TransmissionReportSignatureStatusEnum.Signed ||
      report.signatureStatus ===
        TransmissionReportSignatureStatusEnum.SIGNATURE_NOT_REQUIRED
    ) {
      setZeroSign(
        report.signatureStatus ===
          TransmissionReportSignatureStatusEnum.SIGNATURE_NOT_REQUIRED,
      )
      setSignedReportId(report.id)
    }
    if (
      report.signatureStatus === TransmissionReportSignatureStatusEnum.Dismissed
    ) {
      setVendorTransmissionId(report.id)
    }
  }

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  const unreviewedReports = transmissionReports.filter(
    report => !report.isPhysicianSigned,
  ).length

  if (patientIsLoading) return <FormLoading />

  return (
    <Box>
      <HStack alignItems="flex-start" spacing="5xl" mt="4xl">
        <Stack spacing="3xl" w="300px" minW="300px">
          <PatientDetailsCard
            patient={patient ?? {}}
            device={patient?.matchDevices ?? []}
            refetchPatient={refetchPatient}
            isManagedInRS={isManagedInRS}
            isLoadingOrgData={isLoadingOrgData}
          />
          {isClinicalNotesEnabled && (
            <PinnedNotesCard onClick={() => setTabIndex(3)} />
          )}
          {nextScheduleTranmission?.length ? (
            <Stack>
              <Text fontSize="lg" fontWeight="bold" mb="2">
                Schedule
              </Text>
              <Text fontSize="md" mb="4">
                Up Next
              </Text>
              <Stack>
                {nextScheduleTranmission?.map(transmission => {
                  return (
                    <UpcomingScheduleCard
                      key={transmission.deviceId}
                      date={transmission.nextScheduledDate}
                      transmissionType={transmission.transmissionType}
                      days={transmission.scheduleFrequency}
                      device={transmission.deviceType}
                    />
                  )
                })}
              </Stack>
            </Stack>
          ) : (
            <Stack>
              <Text fontSize="lg" fontWeight="bold" mb="2">
                No Upcoming Transmissions
              </Text>
            </Stack>
          )}
        </Stack>
        <Stack flex={1} spacing="5xl" minW={0}>
          <Tabs index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>
                <span>Reports</span>
                {unreviewedReports > 0 && (
                  <Circle
                    size="18px"
                    bg="feedback.error"
                    color="neutral.white"
                    fontSize="sm"
                    fontWeight="bold"
                    ml="md"
                  >
                    {unreviewedReports.toString()}
                  </Circle>
                )}
              </Tab>
              <Tab>
                <span>Device Schedule</span>
                {showErrorFlag && (
                  <Circle
                    size="18px"
                    bg="feedback.error"
                    color="neutral.white"
                    fontSize="sm"
                    fontWeight="bold"
                    ml="md"
                  >
                    !
                  </Circle>
                )}
              </Tab>
              {isClinicalNotesEnabled && (
                <Tab>
                  <span>Notes</span>
                </Tab>
              )}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing="md">
                  <MedicalDetailsCard
                    patient={patient ?? {}}
                    practitioner={
                      patient?.practitioner ?? { given: '-', family: '' }
                    }
                    onEditMedical={() => setEditMedicalDetailModalIsOpen(true)}
                  />
                </Stack>
              </TabPanel>
              <TabPanel>
                <ReportsList
                  reports={transmissionReports}
                  patient={patient}
                  onDownload={onDownload}
                  ehrIntegration={ehrIntegration}
                />
              </TabPanel>
              <TabPanel>
                <Stack spacing="md">
                  <DeviceDetailsWithTransmissionSchedule
                    isInActivePatient={['Inactive', 'inactive'].includes(
                      patient?.patientStatus ?? '',
                    )}
                    organizationId={patient?.clinic?.parentId ?? ''}
                    devicesArray={patient?.matchDevices ?? []}
                    onViewHistory={() => setDeviceHistoryModalIsOpen(true)}
                    onEditDevice={() => setEditMedicalDetailModalIsOpen(true)}
                  />
                </Stack>
              </TabPanel>
              {isClinicalNotesEnabled && (
                <TabPanel>
                  <ClinicalNotes />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Stack>
      </HStack>
      <Flex
        flexDirection="column"
        mt="5xl"
        //mb={isReviewMode ? '75px' : 0}
        //  ml={reviewSideBarIsOpen ? `${SIDEBAR_OPEN_WIDTH}px` : 0}
        width="100px"
        transition="all 0.25s ease"
        transitionProperty="margin, width"
      >
        <EditPatientDetailsModal
          patient={patient ?? {}}
          device={patient?.matchDevices ?? []}
          isOpen={editPatientDetailModalIsOpen}
          onClose={() => setEditPatientDetailModalIsOpen(false)}
          onSave={() => setEditPatientDetailModalIsOpen(false)}
        />

        <EditMedicalDetailsModal
          patient={patient ?? {}}
          practitioner={patient?.practitioner ?? { given: '-', family: '' }}
          isOpen={editMedicalDetailModalIsOpen}
          ehrIntegration={ehrIntegration}
          onClose={() => setEditMedicalDetailModalIsOpen(false)}
          setEditMedicalDetailModalIsOpen={setEditMedicalDetailModalIsOpen}
        />
        {signedReportId !== null && (
          <ViewPDF
            isOpen={signedReportId !== null}
            onClose={() => setSignedReportId(null)}
            transmissionId={parseInt(signedReportId, 10)}
            rhythmid={rhythmidd!}
            urlegm={undefined}
            practitionGet={undefined}
            isReportList={true}
            clinicAddress={undefined}
            devicetype={undefined}
            isZeroSign={zeroSign}
            saveCombinedPDF={orgData?.organization.saveCombinedPDF}
          />
        )}

        {vendorTransmissionId !== null && (
          <ViewReportListModal
            isOpen={vendorTransmissionId !== null}
            onClose={() => setVendorTransmissionId(null)}
            patient={patient}
            transmissionId={parseInt(vendorTransmissionId, 10)}
          />
        )}

        <DeviceHistoryModal
          isOpen={deviceHistoryModalIsOpen}
          onClose={() => setDeviceHistoryModalIsOpen(false)}
          patientName={`${patient?.givenName} ${patient?.familyName}`}
          devices={patient?.matchDevices ?? []}
        />
      </Flex>
    </Box>
  )
}

const transmissionMapping: Record<string, string> = {
  remote: '',
  'remote-in-clinic': 'Remote In-clinic',
  'in-clinic': 'Manual In-clinic',
}

function generateFileName(e: PatientTransmissionReport, device_type: string) {
  const reportType = transmissionMapping?.[e.transmissionType] ?? 'Manual'

  let reportSuffix = 'Report'
  if (e.transmissionType === 'remote') {
    reportSuffix =
      device_type +
      (e.flagHeartFailure && e.transmissionType === 'remote'
        ? ` Heart Failure ${reportSuffix}`
        : ` ${reportSuffix}`)
  }

  const reportTypeText = reportType ? `${reportType} ` : ''

  if (
    e.signatureStatus === TransmissionReportSignatureStatusEnum.Signed &&
    e.color === TramssionReportColor.RED
  ) {
    return `Red Alert ${reportTypeText}${reportSuffix}`
  }
  if (
    e.signatureStatus === TransmissionReportSignatureStatusEnum.Signed &&
    e.color === TramssionReportColor.YELLOW
  ) {
    return `Yellow Alert ${reportTypeText}${reportSuffix}`
  }
  if (!e.isPhysicianSigned && e.color === TramssionReportColor.RED) {
    return `Pending Red Alert ${reportTypeText}${reportSuffix}`
  }
  if (!e.isPhysicianSigned && e.color === TramssionReportColor.YELLOW) {
    return `Pending Yellow Alert ${reportTypeText}${reportSuffix}`
  }
  if (
    e.signatureStatus === TransmissionReportSignatureStatusEnum.Signed ||
    (['in-clinic', 'remote-in-clinic'].includes(e.transmissionType) &&
      e.signatureStatus ===
        TransmissionReportSignatureStatusEnum.SIGNATURE_NOT_REQUIRED)
  ) {
    return `Routine ${reportTypeText}${reportSuffix}`
  }
  if (e.transmissionType === 'remote-in-clinic' && !e.isPhysicianSigned) {
    return 'Pending Remote In-clinic ' + reportSuffix
  }
  if (e.transmissionType === 'in-clinic' && !e.isPhysicianSigned) {
    return 'Pending Manual In-clinic ' + reportSuffix
  }
  if (e.transmissionType === 'manual' && !e.isPhysicianSigned) {
    return 'Pending Manual ' + reportSuffix
  }
  if (!e.isPhysicianSigned) {
    return `Pending ${reportTypeText}${reportSuffix}`
  }
  return `Dismissed ${reportTypeText}${reportSuffix}`
}

export default PatientProfilePage
