import { theme } from '@rhythm/components'
import {
  ControlProps,
  CSSObjectWithLabel,
  GroupBase,
  OptionProps,
  StylesConfig,
} from 'react-select'

export const DefaultMultiSelectStyles = <
  T,
  isMulti extends boolean,
>(): StylesConfig<T, isMulti, GroupBase<T>> => {
  return {
    option: (base: CSSObjectWithLabel, state: OptionProps<T>) => ({
      ...base,
      backgroundColor: state.isSelected
        ? theme.colors.primary[400]
        : state.isFocused
          ? theme.colors.neutral[200]
          : 'transparent',
      color: state.isDisabled
        ? theme.colors.neutral[600]
        : state.isSelected
          ? theme.colors.neutral.white
          : theme.colors.neutral.black,
      cursor: 'pointer',
      ':active': {},
    }),
    control: (base: CSSObjectWithLabel, state: ControlProps<T>) => ({
      ...base,
      borderColor: state.isFocused
        ? theme.colors.primary[400]
        : theme.colors.neutral[400],
      // borderRadius: theme.radii.sm,
      // borderWidth: '0.5px',
      boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.1)',
      ':hover': {
        borderColor: state.isFocused
          ? theme.colors.primary[400]
          : theme.colors.neutral[600],
      },
    }),
    menu: (base: CSSObjectWithLabel) => ({
      ...base,
      zIndex: 10,
    }),
    clearIndicator: (base: CSSObjectWithLabel) => ({
      ...base,
      color: theme.colors.neutral['600'],
      '&:hover': {
        color: theme.colors.neutral['800'],
      },
      cursor: 'pointer',
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
      ...base,
      color: theme.colors.neutral[600],
    }),
  }
}
