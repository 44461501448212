import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  Search,
  Stack,
  Text,
  TextButton,
  Tooltip,
  VStack,
} from '@rhythm/components'

import { useApiContext } from '../../../../../context/ApiContext'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import {
  queryClient,
  Roles,
  TransmissionReportDto,
  TransmissionReportSignatureStatusEnum,
} from '../../../../../lib/api'
import routes from '../../../../../routes/config'
import { Patient, PatientTransmissionReport } from '../../../../../types'
import formatDate from '../../../../../utils/formatDate'
import { DollarSignIconBlack } from '../../../TransmissionReportPage/components/BillingCard/CustomIcons'
import ReportCard from '../ReportCard'
import { FilesUploadStatus } from '../ReportCard/ReportCard'

import { AddReport } from './AddReport'
import { FilterByReportColor } from './FilterByReportColor'
import { FilterByReportType } from './FilterByReportType'
import { ReportFilterOption } from './ReportFilterPanel'

export interface ReportsListProps {
  reports: PatientTransmissionReport[]
  onDownload: (report: PatientTransmissionReport) => void
  ehrIntegration?: boolean
  patient?: Patient | undefined
}

const ReportsList = ({
  reports,
  onDownload,
  ehrIntegration,
  patient,
}: ReportsListProps): React.ReactElement => {
  const history = useHistory()
  const [deactiveReportId, setDeactiveReportId] = useState<null | number>(null)
  const [showAddDevice, setShowAddDevice] = useState<boolean>(false)
  const [reportTypeFilter, setReportTypeFilter] = useState<ReportFilterOption>({
    label: 'All',
    value: 'all',
  })
  const [reportColorFilter, setReportColorFilter] =
    useState<ReportFilterOption>({
      label: 'All',
      value: 'all',
    })
  const [isLoading, setIsLoading] = useState(false)
  const { currentUser } = useCurrentUser()

  const Api = useApiContext()
  //const toast = useToast()
  const isPractitioner: boolean =
    currentUser.role === Roles.ExternalpractitionerAdmin ||
    currentUser.role === Roles.Externalpractitioner ||
    currentUser.role === Roles.Externalprovider

  reports.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  const [reportList, setReportList] =
    useState<PatientTransmissionReport[]>(reports)
  let canNotEditUser: boolean = false
  if (
    currentUser.role === Roles.InternalnonClinical ||
    currentUser.role === Roles.Externalclerical ||
    currentUser.role === Roles.ExternalheartFailure
  ) {
    canNotEditUser = true
  }
  let canNotAddReports: boolean = false
  if (
    currentUser.role === Roles.InternalnonClinical ||
    currentUser.role === Roles.Externalclerical ||
    currentUser.role === Roles.ExternalheartFailure
  ) {
    canNotAddReports = true
  }

  const onReviewReport = async (id: string) => {
    if (id.length === 0) return
    setIsLoading(true)
    const currentReportObj = await currentReport(Number(id))
    history.push({
      pathname: routes.physician.transmissionReview,
      state: {
        editReportId: id,
        isEditing: false,
        currentReport: currentReportObj,
        defaultExpanded: currentReportObj.color,
      },
    })
  }
  const onEdit = async (id: string) => {
    if (id.length === 0) return
    const currentReportObj = await currentReport(Number(id))
    history.push({
      pathname: routes.physician.transmissionReview,
      state: {
        editReportId: id,
        isEditing: true,
        currentReport: currentReportObj,
        defaultExpanded: currentReportObj.color,
      },
    })
  }

  const onConfirm = async () => {
    if (!deactiveReportId) return
    setIsLoading(true)
    await Api.transmissionReportsControllerUnDismiss({
      transmissionReportId: deactiveReportId,
    })
    history.push({
      pathname: routes.physician.transmissionReview,
      state: {
        editReportId: deactiveReportId,
        isEditing: false,
        currentReport: await currentReport(Number(deactiveReportId)),
      },
    })
  }

  const onSearchTextChange = (term: string) => {
    if (!term) {
      setReportList(reports)
    } else {
      const result = reports.filter((e: PatientTransmissionReport) => {
        let isExist = false
        if (
          e.fileName.toUpperCase().indexOf(term.toUpperCase()) > -1 ||
          formatDate(e.updatedAt).indexOf(term) > -1
        ) {
          isExist = true
        }
        return isExist
      })
      setReportList(result)
    }
  }

  const currentReport = async (id: number): Promise<TransmissionReportDto> => {
    const { data } = await Api.transmissionReportsControllerFindOne({
      transmissionReportId: id,
    })
    return data.transmissionReport
  }

  const onContinueAddReport = (isLoadList?: boolean) => {
    if (isLoadList) {
      setShowAddDevice(false)
      queryClient.invalidateQueries(['patient'])
      queryClient.invalidateQueries(['patientDevice'])
    }
  }

  useEffect(() => {
    if (reportTypeFilter.value === 'all' && reportColorFilter.value === 'all') {
      setReportList(reports)
      return
    }
    let newFilterValues: PatientTransmissionReport[] = [...reports]
    if (reportTypeFilter.value !== 'all') {
      newFilterValues = newFilterValues.filter(
        (report: PatientTransmissionReport) => {
          return report.transmissionType === reportTypeFilter.value
        },
      )
    }
    if (reportColorFilter.value !== 'all') {
      newFilterValues = newFilterValues.filter(
        (report: PatientTransmissionReport) => {
          return report.color === reportColorFilter.value
        },
      )
    }

    setReportList(newFilterValues)
  }, [reportTypeFilter, reportColorFilter, reports])

  const isPendingReview = (
    signatureStatus: TransmissionReportSignatureStatusEnum,
  ) => {
    return (
      (isPractitioner &&
        signatureStatus ===
          TransmissionReportSignatureStatusEnum.NonPractitionerSigned) ||
      (!isPractitioner &&
        signatureStatus === TransmissionReportSignatureStatusEnum.NotSigned)
    )
  }

  return (
    <Stack spacing="xl">
      {deactiveReportId && (
        <UnDismissConfirmModal
          isLoading={isLoading}
          onClose={() => setDeactiveReportId(null)}
          onConfirm={() => onConfirm()}
        />
      )}
      {currentUser && showAddDevice && (
        <AddReport
          patient={patient}
          onClose={() => setShowAddDevice(false)}
          onContinue={onContinueAddReport}
        />
      )}
      <HStack
        alignContent={'center'}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <div style={{ width: '40%' }}>
          <Search
            size="md"
            onDebouncedSearch={onSearchTextChange}
            leftIcon={{
              icon: 'search',
              color: 'neutral.600',
              size: 'sm',
            }}
            rightIcon={{
              icon: 'close-circle',
              color: 'neutral.black',
              size: 'sm',
            }}
            style={{
              alignContent: 'center',
            }}
          />
        </div>
        <FilterByReportColor onMenuItemClick={setReportColorFilter} />
        <Center height="30px">
          <Divider orientation="vertical" />
        </Center>
        <FilterByReportType onMenuItemClick={setReportTypeFilter} />
        <Center height="30px">
          <Divider orientation="vertical" />
        </Center>
        {!canNotAddReports && (
          <TextButton
            onClick={() => setShowAddDevice(true)}
            py={0}
            pl={3}
            pr={'20px'}
          >
            <Icon icon={'add'} boxSize={5} /> &nbsp;&nbsp; Add Reports
          </TextButton>
        )}
      </HStack>

      <Stack spacing="md">
        {reportList.length > 0 &&
          reportList.map(report => (
            <ReportCard
              key={report.id}
              status={report.color}
              signatureStatus={report.signatureStatus}
              reportName={report.fileName}
              orderStatus={report.orderStatus}
              showOrderStatus={
                ['remote', 'remote-in-clinic'].includes(
                  report.transmissionType,
                ) &&
                report.signatureStatus !==
                  TransmissionReportSignatureStatusEnum.Dismissed
                  ? ehrIntegration
                  : false
              }
              billingDate={(report.billing?.billingDate ?? '') as string}
              reportCreatedDate={report.vtTransmisisonDate as string}
              editedUser={report?.editedUser as string}
              editedDate={report?.editedDate as string}
              filesUploadStatus={
                report.filesUploadStatus as unknown as FilesUploadStatus
              }
              transmissionType={report.transmissionType as string}
            >
              <Stack
                spacing="xl"
                align={'end'}
                justify={'start'}
                style={{ width: '100%' }}
              >
                <Flex
                  style={{
                    width: '100%',
                    position: 'relative',
                    paddingLeft: '14px',
                  }}
                >
                  {isPendingReview(report.signatureStatus) && (
                    <Button
                      style={{ right: '0px' }}
                      onClick={() => onReviewReport(report.id as string)}
                      isLoading={isLoading}
                    >
                      Review Now
                    </Button>
                  )}
                  {!isPractitioner &&
                    report?.signatureStatus ===
                      TransmissionReportSignatureStatusEnum.NonPractitionerSigned &&
                    !canNotEditUser && (
                      <Menu autoSelect={false}>
                        <MenuButton
                          style={{
                            position: 'absolute',
                            right: '0px',
                            top: '17px',
                          }}
                        >
                          <HStack spacing="sm">
                            <Icon
                              icon="more-vertical"
                              boxSize="sm"
                              color="text.secondary"
                            />
                          </HStack>
                        </MenuButton>
                        <MenuList style={{ minWidth: '30%' }}>
                          <MenuItem onClick={() => onEdit(report.id as string)}>
                            Edit Report
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  {report.billing?.isBillable &&
                    !isPendingReview(report.signatureStatus) && (
                      <Tooltip
                        hasArrow
                        placement="right"
                        label={'Billed Report'}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            right: '24px',
                            top: '15px',
                          }}
                        >
                          <DollarSignIconBlack width="12" height="20" />
                        </div>
                      </Tooltip>
                    )}
                  {isDownloadVisble(report) && (
                    <Menu autoSelect={false}>
                      <MenuButton
                        style={{
                          position: 'absolute',
                          right: '0px',
                          top: '17px',
                        }}
                      >
                        <HStack spacing="sm">
                          <Icon
                            icon="more-vertical"
                            boxSize="sm"
                            color="text.secondary"
                          />
                        </HStack>
                      </MenuButton>
                      <MenuList style={{ minWidth: '30%' }}>
                        {!canNotEditUser &&
                          report.signatureStatus !==
                            TransmissionReportSignatureStatusEnum.Dismissed &&
                          report.signatureStatus !==
                            TransmissionReportSignatureStatusEnum.SIGNATURE_NOT_REQUIRED && (
                            <MenuItem
                              onClick={() => onEdit(report.id as string)}
                            >
                              Edit Report
                            </MenuItem>
                          )}
                        {report?.signatureStatus ===
                          TransmissionReportSignatureStatusEnum.Dismissed && (
                          <MenuItem
                            onClick={() =>
                              setDeactiveReportId(Number(report.id))
                            }
                          >
                            Activate
                          </MenuItem>
                        )}
                        <MenuItem onClick={() => onDownload(report)}>
                          View & Download
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Flex>
              </Stack>
            </ReportCard>
          ))}
        {reportList.length === 0 && (
          <HStack style={{ justifyContent: 'center', display: 'flex' }}>
            <Text
              variant="h5"
              style={{
                fontWeight: 'bold',
              }}
            >
              No Report(s) Available
            </Text>
          </HStack>
        )}
      </Stack>
    </Stack>
  )
}

const isDownloadVisble = (report: PatientTransmissionReport): boolean => {
  return (
    report.signatureStatus === TransmissionReportSignatureStatusEnum.Signed ||
    report.signatureStatus ===
      TransmissionReportSignatureStatusEnum.Dismissed ||
    report.signatureStatus ===
      TransmissionReportSignatureStatusEnum.SIGNATURE_NOT_REQUIRED
  )
}

const UnDismissConfirmModal = ({
  onClose,
  isLoading,
  onConfirm,
}: {
  onClose: () => void
  isLoading: boolean
  onConfirm: () => void
}) => {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={
        <HStack spacing="xl">
          <Button
            variant="secondaryDark"
            onClick={onClose}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button onClick={onConfirm} isLoading={isLoading}>
            Confirm
          </Button>
        </HStack>
      }
    >
      <VStack spacing="2xl">
        <Box>
          <Text fontWeight={'bold'} fontSize={'16px'}>
            Activate Report
          </Text>
        </Box>
        <Box w="full">
          <Text>
            Activating will create a report in RhythmSynergy using the dismissed
            report’s information. Do you want to continue?
          </Text>
        </Box>
      </VStack>
    </Modal>
  )
}

export default ReportsList
