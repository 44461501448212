import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Card,
  Center,
  Divider,
  Editable,
  Error,
  Flex,
  Form,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconBadge,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useControllableState,
  VStack,
} from '@rhythm/components'
import { debounce, get } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { useApiContext } from '../../../../../context/ApiContext'
import { useFeatureFlagsContext } from '../../../../../context/FeatureFlagsContext'
import { useUpdatePatient } from '../../../../../features/patients'
import {
  getDefaultTransmissionValuesForPatient,
  useTransmissionReportContext,
  useUpdateTransmissionReport,
} from '../../../../../features/transmissionReports'
import {
  DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest,
  DefaultApiTransmissionReportsControllerUpdateRequest,
  queryClient,
  RegisteredNpis,
  TransmissionReportDto,
  TransmissionReportSignatureStatuses,
  UpdatePatientParams,
  UpdateTransmissionReportParams,
} from '../../../../../lib/api'
import {
  FOLLOW_UP_OPTIONS,
  OAC_OPTIONS,
  REASONS_FOR_MONITORING_OPTIONS,
} from '../../../../../utils/constants/transmissionReports'
import { LabelAndValue } from '../../../PatientsPage/constants'
import ReferringPhysicianModal from '../../../RegisteredNpi/ReferringPhysician'
import { PractitionerOption } from '../../TransmissionReportPage'

import routes from './../../../../../../src/routes/config'

export interface PatientDemographicsCardProps {
  transmissionReport?: TransmissionReportDto
  ReasonForMonitorILR?: boolean
  practitionerOptions?: PractitionerOption[]
  ehrIntegration?: boolean
  icdDianosisCodes?: LabelAndValue[]
}
export function capitalizeFirstCharacter(input: string) {
  return input
    .replace(/\b\w/g, function (char) {
      return char.toUpperCase()
    })
    .replace(/\B\w/g, function (char) {
      return char.toLowerCase()
    })
}

const PatientDemographicsCard = ({
  transmissionReport,
  ReasonForMonitorILR,
  practitionerOptions,
  ehrIntegration,
  icdDianosisCodes = [],
}: PatientDemographicsCardProps): React.ReactElement => {
  const {
    clinicalNotesSideBarIsOpen,
    setClinicalNotesSideBarIsOpen,
    setIsBillingLoading,
  } = useTransmissionReportContext()
  const { hasFeatureFlag } = useFeatureFlagsContext()
  const isClinicalNotesEnabled = hasFeatureFlag('clinicalNotes')
  const isLoading = transmissionReport === undefined
  const {
    id: patientId,
    givenName,
    familyName,
    clinic,
    referringProvider,
    referringPhysician,
  } = transmissionReport?.patient || {}
  const [editMedicalDetailModalIsOpen, setEditMedicalDetailModalIsOpen] =
    useState(false)
  const defaultValues: Record<string, any> = useMemo(
    () => getDefaultTransmissionValuesForPatient(transmissionReport?.patient),
    [transmissionReport?.patient],
  )

  const isInClinicReport =
    transmissionReport?.transmissionType === 'in-clinic' ||
    transmissionReport?.transmissionType === 'remote-in-clinic'

  const { mutateAsync: updatePatient, error } = useUpdatePatient()
  const { mutateAsync: updateTransmissionReport, error: txReportUpdateError } =
    useUpdateTransmissionReport()

  const { mutateAsync: updateDemographicsData, error: demographicsError } =
    useMutation({
      mutationFn: async (
        updateTransmissionReportParams: DefaultApiTransmissionReportsControllerUpdateRequest,
      ) => {
        await Api.transmissionReportsControllerUpdate(
          updateTransmissionReportParams,
        )
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReport?.id,
        ])
      },
    })

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      displayReferringPhysician: capitalizeFirstCharacter(
        referringProvider
          ? `${referringProvider.firstName} ${referringProvider.lastName} (${referringProvider.NPI})`
          : referringPhysician
            ? referringPhysician
            : '',
      ),
      diagnosisOption: icdDianosisCodes?.find(
        code =>
          code.value === transmissionReport?.patient?.diagnosisOption ||
          code.label === transmissionReport?.patient?.diagnosisOption,
      ),
      followUpReason: transmissionReport?.followUpReason
        ? {
            label: capitalizeFirstCharacter(transmissionReport?.followUpReason),
            value: transmissionReport?.followUpReason,
          }
        : { label: '', value: '' },
      otherReason: transmissionReport?.otherReason,
    } as Record<string, any>,
  })

  useEffect(() => {
    methods.setValue(
      'diagnosisOption',
      icdDianosisCodes?.find(
        code =>
          code.value === transmissionReport?.patient?.diagnosisOption ||
          code.label === transmissionReport?.patient?.diagnosisOption,
      ),
    )
  }, [icdDianosisCodes, methods, transmissionReport?.patient?.diagnosisOption])

  const Api = useApiContext()

  const { mutateAsync: addDiagnosisCodeMutation } = useMutation(
    async (
      addDiagnosisCodeParams: DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest,
    ) => {
      setIsBillingLoading(true)
      await Api.transmissionReportsControllerAddDiagnosisCodeToBilling(
        addDiagnosisCodeParams,
      )
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReport?.id,
        ])
        await queryClient.invalidateQueries(['billing', transmissionReport?.id])
      },
      onError: () => {},
      onSettled: () => {
        setIsBillingLoading(false)
      },
    },
  )

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors },
    watch,
    reset,
  } = methods

  const submit = useCallback(
    async (data: Record<string, any>) => {
      if (!patientId) return

      const updatedField = Object.keys(dirtyFields)[0]
      if (!updatedField) return

      const updatedValue = data[updatedField]

      const isSigned =
        TransmissionReportSignatureStatuses.Signed ===
        (transmissionReport?.signatureStatus as unknown as TransmissionReportSignatureStatuses)

      // Update the in clinic transmission report directly if it is not signed and
      // the field updated is followUpReason or otherReason.
      if (
        !isSigned &&
        transmissionReport?.id &&
        isInClinicReport &&
        ['followUpReason', 'otherReason'].includes(updatedField)
      ) {
        await updateDemographicsData({
          transmissionReportId: transmissionReport.id as number,
          updateTransmissionReportParams: {
            [updatedField]:
              typeof updatedValue !== 'string'
                ? updatedValue?.value
                : updatedValue.trim(),
          },
        })
      }
      // Update the transmission report if it is signed and the field is in the specified list.
      else if (
        isSigned &&
        transmissionReport?.id &&
        [
          'practitionerId',
          'oac',
          'diagnosisOption',
          'referringProvider',
          'followUpReason',
          'otherReason',
        ].includes(updatedField)
      ) {
        await updateTransmissionReport({
          transmissionReportId: transmissionReport.id as number,
          updateTransmissionReportParams: {
            [updatedField]:
              typeof updatedValue !== 'string'
                ? updatedValue?.value
                : updatedValue.trim(),
          } as unknown as UpdateTransmissionReportParams,
        })
      } else {
        await updatePatient({
          patientId,
          updatePatientParams: {
            [updatedField]:
              typeof updatedValue !== 'string'
                ? updatedValue?.value
                : updatedValue.trim(),
          } as unknown as UpdatePatientParams,
        })
      }

      const isNotOtherReason =
        updatedField === 'followUpReason' && updatedValue !== 'other'

      reset({
        ...data,
        otherReason: isNotOtherReason ? '' : data.otherReason,
      })
      setTerm(isNotOtherReason ? '' : data.otherReason)
      return
    },
    [patientId, dirtyFields, updatePatient, reset],
  )

  const watchMonitorReason = watch('monitorReason')
  const watchDiagnosisOption = watch('diagnosisOption')
  const watchOac = watch('oac')
  const watchPractitioner = watch('practitionerId')
  const watchFollowUpReason = watch('followUpReason')
  const watchOtherReason = watch('otherReason')

  const updateBillingCodeWithPatientDiagnosis = useCallback(
    async (newCode: LabelAndValue) => {
      if (transmissionReport?.id && transmissionReport?.billing?.id) {
        await addDiagnosisCodeMutation({
          transmissionReportId: transmissionReport?.id,
          billingId: transmissionReport?.billing?.id,
          reportAddDiagnosisCodeDto: {
            diagnosisCode: {
              value: newCode.value,
              label: newCode.value,
            },
          },
        })
      }
    },
    [
      addDiagnosisCodeMutation,
      transmissionReport?.billing?.id,
      transmissionReport?.id,
    ],
  )

  useEffect(() => {
    handleSubmit(submit)()
  }, [
    watchMonitorReason,
    watchDiagnosisOption,
    watchOac,
    watchPractitioner,
    watchFollowUpReason,
    watchOtherReason,
    handleSubmit,
    submit,
  ])

  const deviceType = transmissionReport?.device

  let report = false
  let iLRReport = true

  const check = 'ILR'
  if (deviceType?.deviceType === check) {
    report = true
    iLRReport = false
  }

  const isFollowingPractitionerReq =
    transmissionReport?.account?.isFollowingPractitionerReq ?? false

  const isPractitionerAssigned =
    transmissionReport?.patient?.practitioner !== null

  const followingPractitionerErrMsg =
    isFollowingPractitionerReq &&
    (practitionerOptions?.length ?? 0) > 0 &&
    !isPractitionerAssigned &&
    (watchPractitioner?.length ?? 0) === 0
      ? 'Please select a Following Practitioner'
      : undefined

  const handleReferringPhysicianSubmit = (data: RegisteredNpis) => {
    const displayProviderName = ehrIntegration
      ? capitalizeFirstCharacter(`${data.firstName} ${data.lastName}`)
      : capitalizeFirstCharacter(
          `${data.firstName} ${data.lastName} (${data.NPI})`,
        )
    methods.setValue('referringProvider', data.NPI, {
      shouldDirty: true,
    })
    methods.setValue('displayReferringPhysician', displayProviderName)
    handleSubmit(submit)()
    setEditMedicalDetailModalIsOpen(false)
  }
  const handleClear = () => {
    methods.setValue('referringProvider', '-', {
      shouldDirty: true,
    })
    methods.setValue('displayReferringPhysician', '')
    handleSubmit(submit)()
  }
  const onViewNotesClick = () =>
    setClinicalNotesSideBarIsOpen(!clinicalNotesSideBarIsOpen)

  const [term, setTerm] = useControllableState({
    defaultValue: transmissionReport?.otherReason ?? '',
  })

  const debounceChangeCallback = useCallback(
    debounce((key: string, value: string) => {
      methods.setValue(key, value, { shouldDirty: true })
    }, 500),
    [],
  )

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setTerm(newValue)

    debounceChangeCallback('otherReason', newValue)
  }

  return (
    <Card width="100%">
      <Flex direction="column">
        <HStack mb="2xl" spacing="xl">
          <IconBadge icon="account" size="lg" />
          <VStack alignItems="flex-start" spacing="sm">
            <Skeleton isLoaded={!isLoading} w={'max'}>
              <HStack direction="column">
                <Heading variant="h4">{`${givenName} ${familyName}`}</Heading>
                <RouterLink
                  to={{
                    pathname:
                      routes.physician.patientProfile.replace(':id', '') +
                      transmissionReport?.patient?.id,
                  }}
                >
                  <Link as={'div'} fontSize={'sm'}>
                    <HStack>
                      <Box>View Profile</Box>
                      <Icon boxSize={'sm'} icon={'arrow-right'} />
                    </HStack>
                  </Link>
                </RouterLink>
                {isClinicalNotesEnabled && (
                  <Center height="20px">
                    <Divider mx={3} orientation="vertical" />
                  </Center>
                )}
                {isClinicalNotesEnabled && (
                  <Link as={'div'} fontSize={'sm'} onClick={onViewNotesClick}>
                    <HStack>
                      <Box>
                        {clinicalNotesSideBarIsOpen ? 'Hide' : 'View'} Notes
                      </Box>
                      <Icon boxSize={'sm'} icon={'arrow-right'} />
                    </HStack>
                  </Link>
                )}
              </HStack>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text color="neutral.800" fontWeight="bold">
                {clinic?.name}
              </Text>
            </Skeleton>
          </VStack>
        </HStack>
        <Form methods={methods} onSubmit={submit} apiError={error}>
          <VStack spacing="xl">
            <Stack spacing="3xl" direction="row" width="100%">
              <FormControl>
                <FormLabel htmlFor="mrn2" mb="lg" hidden={report}>
                  MRN
                </FormLabel>
                <FormLabel htmlFor="mrn2" mb="lg" hidden={iLRReport}>
                  MRN
                </FormLabel>
                <Controller
                  name="mrn2"
                  control={control}
                  render={({ field: { value } }) => (
                    <Editable
                      name="mrn2"
                      value={value}
                      defaultValue={value}
                      isDisabled
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="birthDate" mb="lg">
                  DOB
                </FormLabel>
                <Controller
                  name="birthDate"
                  control={control}
                  render={({ field: { value } }) => (
                    <Editable
                      name="birthDate"
                      value={value}
                      defaultValue={value}
                      isDisabled
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="diagnosisOption">DIAGNOSIS</FormLabel>
                <Controller
                  name="diagnosisOption"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      ref={ref}
                      value={value}
                      options={icdDianosisCodes}
                      isDisabled={isLoading}
                      isInvalid={
                        errors.diagnosisOption as unknown as boolean | undefined
                      }
                      onChange={newValue => {
                        onChange(newValue)
                        updateBillingCodeWithPatientDiagnosis(
                          newValue as unknown as LabelAndValue,
                        )
                      }}
                    />
                  )}
                />
                {errors.monitorReason && (
                  <Error
                    message={get(errors, 'monitorReason.message', '') as string}
                  />
                )}
              </FormControl>
            </Stack>
            <Stack spacing="3xl" direction="row" width="100%">
              <FormControl>
                <FormLabel htmlFor="oac">OAC</FormLabel>
                <Controller
                  name="oac"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      ref={ref}
                      value={value}
                      options={OAC_OPTIONS}
                      isDisabled={isLoading}
                      isInvalid={errors.oac as unknown as boolean | undefined}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.oac && (
                  <Error message={get(errors, 'oac.message', '') as string} />
                )}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="referringProvider" mb="lg">
                  REFERRING PROVIDER
                </FormLabel>
                <Flex>
                  <InputGroup>
                    {!methods.watch('displayReferringPhysician')?.length && (
                      <InputLeftElement>
                        <Icon
                          aria-label="Clear input"
                          icon="search"
                          bg={'none'}
                          color={'#8390a2'}
                          boxSize="sm"
                        />
                      </InputLeftElement>
                    )}
                    <Input
                      {...methods.register('displayReferringPhysician')}
                      name="displayReferringPhysician"
                      onClick={() => {
                        if (
                          !methods.watch('displayReferringPhysician')?.length
                        ) {
                          setEditMedicalDetailModalIsOpen(true)
                        }
                      }}
                      placeholder="        Search"
                      isReadOnly
                    />
                    {methods.watch('displayReferringPhysician')?.length && (
                      <InputRightElement>
                        <IconButton
                          aria-label="Clear input"
                          icon="close"
                          onClick={handleClear}
                          bg={'none'}
                          color={'black'}
                          _hover={{ bg: '#dee1e6', borderRadius: '50%' }}
                          size="sm"
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  {methods.watch('displayReferringPhysician')?.length ? (
                    <IconButton
                      aria-label="Clear input"
                      icon="edit"
                      onClick={() => setEditMedicalDetailModalIsOpen(true)}
                      bg={'none'}
                      color={'black'}
                      _hover={{ bg: '#dee1e6', borderRadius: '50%' }}
                      size="sm"
                      ml={'2px'}
                    />
                  ) : null}
                </Flex>
              </FormControl>
              <ReferringPhysicianModal
                isOpen={editMedicalDetailModalIsOpen}
                onClose={() => setEditMedicalDetailModalIsOpen(false)}
                onSubmit={handleReferringPhysicianSubmit}
              />
              <FormControl>
                <FormLabel htmlFor="practitionerId">
                  FOLLOWING PRACTITIONER
                </FormLabel>
                <Controller
                  name="practitionerId"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      ref={ref}
                      isLoading={isLoading}
                      options={practitionerOptions}
                      //onFocus={refetchPatientPractitioners}
                      isDisabled={isLoading}
                      isInvalid={
                        get(errors, 'practitionerId', false) as boolean
                      }
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {followingPractitionerErrMsg && (
                  <Error message={followingPractitionerErrMsg} />
                )}
              </FormControl>
            </Stack>
            <SimpleGrid spacing="3xl" columns={3} width="100%">
              {ReasonForMonitorILR && (
                <FormControl>
                  <FormLabel htmlFor="monitorReason">
                    REASON FOR MONITORING
                  </FormLabel>
                  <Controller
                    name="monitorReason"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        options={REASONS_FOR_MONITORING_OPTIONS}
                        isDisabled={isLoading}
                        isInvalid={
                          errors.monitorReason as unknown as boolean | undefined
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.monitorReason && (
                    <Error
                      message={
                        get(errors, 'monitorReason.message', '') as string
                      }
                    />
                  )}
                </FormControl>
              )}
              {isInClinicReport && (
                <>
                  <FormControl hidden={!isInClinicReport}>
                    <FormLabel htmlFor="followUpReason">
                      REASON FOR FOLLOW UP
                    </FormLabel>
                    <Controller
                      name="followUpReason"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          ref={ref}
                          value={value}
                          options={FOLLOW_UP_OPTIONS}
                          isDisabled={isLoading}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.followUpReason && (
                      <Error
                        message={
                          get(errors, 'followUpReason.message', '') as string
                        }
                      />
                    )}
                  </FormControl>
                  {watchFollowUpReason?.value === 'other' && (
                    <FormControl>
                      <FormLabel htmlFor="otherReason">OTHER REASON</FormLabel>
                      <Controller
                        name="otherReason"
                        control={control}
                        render={({ field: { ref } }) => (
                          <Input
                            value={term}
                            ref={ref}
                            onChange={handleChange}
                            placeholder="Enter other reason"
                          />
                        )}
                      />
                      {errors.otherReason && (
                        <Error
                          message={
                            get(errors, 'otherReason.message', '') as string
                          }
                        />
                      )}
                    </FormControl>
                  )}
                </>
              )}
            </SimpleGrid>
          </VStack>
        </Form>
      </Flex>
    </Card>
  )
}

export default PatientDemographicsCard
