import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Flex, FormControl, Text, Textarea } from '@rhythm/components'
import { useForm, useWatch } from 'react-hook-form'
import { useMutation } from 'react-query'
import * as yup from 'yup'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerUpdateRequest,
  queryClient,
  TransmissionReportDto,
  UpdateTransmissionReportParams,
} from '../../../../lib/api'
import NarrativeCard from '../../../../pages/physician/TransmissionReportPage/components/NarrativeCard'

export interface ProgrammingChangesProps {
  transmissionReport: TransmissionReportDto
}

type FormType = {
  programmingChanges?: string
}

const schema = yup.object({
  programmingChanges: yup
    .string()
    .nullable()
    .max(2000, 'Maximum of 2000 characters allowed.')
    .optional(),
})

const ProgrammingChanges = ({
  transmissionReport,
}: ProgrammingChangesProps) => {
  const Api = useApiContext()

  const [hasInteracted, setHasInteracted] = useState(false) // Tracks if the form has been interacted with

  const methods = useForm<FormType>({
    defaultValues: {
      programmingChanges: transmissionReport.programmingChanges ?? '',
    } as FormType,
    mode: 'onBlur', // Validation triggers on onBlur
    resolver: yupResolver(schema),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods

  const formValues = useWatch({ control }) // Watch all form values for changes

  const { mutateAsync: updatePresentingData, isLoading } = useMutation({
    mutationFn: async (
      updateTransmissionReportParams: DefaultApiTransmissionReportsControllerUpdateRequest,
    ) => {
      await Api.transmissionReportsControllerUpdate(
        updateTransmissionReportParams,
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        'transmissionReport',
        transmissionReport?.id,
      ])
    },
  })

  const onSubmit = async (data: FormType) => {
    await updatePresentingData({
      transmissionReportId: transmissionReport.id,
      updateTransmissionReportParams: data as UpdateTransmissionReportParams,
    })
  }

  // Trigger API call whenever formValues change
  useEffect(() => {
    if (hasInteracted) {
      const debounce = setTimeout(() => {
        handleSubmit(onSubmit)()
      }, 500) // Adds a debounced of 500ms to prevent rapid saves

      return () => clearTimeout(debounce)
    }
  }, [formValues, handleSubmit, hasInteracted])

  const handleInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true)
    }
  }

  return (
    <NarrativeCard
      icon="file"
      title="Programming Changes"
      isLoading={isLoading}
    >
      <form onChange={handleInteraction}>
        <Flex direction="row">
          <FormControl>
            <Textarea
              width={'100%'}
              {...methods.register('programmingChanges')}
              variant={'default'}
              noOfLines={3}
              placeholder={'Notes...'}
            />
            {errors?.programmingChanges && (
              <Text mt={1} color={'red.400'}>
                {errors.programmingChanges?.message}
              </Text>
            )}
          </FormControl>
        </Flex>
      </form>
    </NarrativeCard>
  )
}

export default ProgrammingChanges
