import { useCallback, useMemo } from 'react'

import { NoteFieldValue, Notes } from '../../../../../components/Notes'
import {
  useTransmissionReportContext,
  useTransmissionReportNotes,
} from '../../../../../features/transmissionReports'
import {
  TransmissionReportLocations,
  TransmissionReportNoteLocationEnum,
} from '../../../../../lib/api'
import NarrativeCard from '../NarrativeCard'

const TransmissionNotesCard = ({ onDeleteNote }: any) => {
  const { transmissionReport, isLoading } = useTransmissionReportContext()
  const {
    createNote,
    updateNote,
    deleteNote,
    isLoading: isApiLoading,
  } = useTransmissionReportNotes()
  const { notes, id: transmissionReportId } = transmissionReport ?? {}

  const transmissionNotes = useMemo(
    () =>
      notes?.filter(
        note =>
          note.location === TransmissionReportNoteLocationEnum.Transmission,
      ),
    [notes],
  )

  const maxNotesCount: number =
    transmissionReport?.transmissionType === 'remote' ? 5 : 10

  const handleNoteMutation = useCallback(
    ({ id, note, flag }: NoteFieldValue) => {
      if (transmissionReportId && !id) {
        createNote({
          transmissionReportId,
          flag,
          transmissionReportNoteCreateParams: {
            location: TransmissionReportLocations.Transmission,
            note,
          },
        })
      } else if (transmissionReportId && id) {
        updateNote({
          transmissionReportId,
          noteId: id,
          transmissionReportNoteUpdateParams: { note },
        })
      }
    },
    [createNote, updateNote, transmissionReportId],
  )

  const handleNoteDelete = useCallback(
    (noteId: string) => {
      if (transmissionReportId && noteId) {
        deleteNote({
          transmissionReportId: transmissionReportId.toString(),
          noteId,
        })
      }
      onDeleteNote(noteId)
    },
    [transmissionReportId, deleteNote, onDeleteNote],
  )

  return (
    <NarrativeCard title="Technician Interpretation" icon="file">
      <Notes
        key={`transmission-report-${transmissionReportId}-notes`}
        notes={transmissionNotes}
        groupName="transmission-report-notes"
        onSubmit={handleNoteMutation}
        onDelete={handleNoteDelete}
        isLoading={isLoading || isApiLoading}
        max={maxNotesCount}
      />
    </NarrativeCard>
  )
}

export default TransmissionNotesCard
