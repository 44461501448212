import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApiVtVendorControllerLeadVendorListRequest,
  queryClient,
} from '../../../../lib/api'

export const useVtLeadVendorList = () => {
  const Api = useApiContext()

  return useMutation(
    () => {
      return Api.transmissionReportsControllerLeadVendorList()
    },
    {
      onSuccess: res => {
        queryClient.setQueryData(['leadVendorList'], res.data)
      },
    },
  )
}
