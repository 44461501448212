import { Box, Select } from '@rhythm/components'

import { VtDeviceLead } from '../../../../../lib/api/generated-client'
import { DeviceLeadLocation } from '../../../../../types'

import { VendorOptions } from './DeviceDemographicsCard'
import { DeviceLeadEmptyState } from './DeviceLeadEmptyState'

export interface DeviceLeadVendorProps {
  deviceLeads?: VtDeviceLead[]
  leadVendorsOptions: VendorOptions[]
  isDisabled?: boolean
  onChange: (value: any) => void
}

export const DeviceLeadVendor = ({
  deviceLeads,
  leadVendorsOptions,
  isDisabled = false,
  onChange,
}: DeviceLeadVendorProps) => {
  return (
    <>
      {Object.keys(DeviceLeadLocation).map((location, _i) => {
        const deviceLead = deviceLeads?.find(
          lead => lead.leadLocation === location,
        )

        const defaultValue =
          (deviceLead?.vendorId &&
            leadVendorsOptions.find(
              option => option.value === Number(deviceLead.vendorId),
            )) ||
          null

        if (deviceLead) {
          return (
            <Box
              py="xl"
              pr="2xl"
              key={`deviceLeads.vendor-${deviceLead.leadId}`}
              borderBottomWidth="1px"
              borderBottomColor="neutral.200"
            >
              <Select
                value={defaultValue}
                options={leadVendorsOptions}
                isDisabled={isDisabled}
                onChange={(newValue: any) => {
                  // Type guard to check if newValue is a VendorOptions object
                  if (
                    typeof newValue === 'object' &&
                    newValue !== null &&
                    'value' in newValue
                  ) {
                    const selectedOption = newValue as VendorOptions
                    onChange({
                      name: 'deviceLeads.vendorId',
                      value: selectedOption.value,
                      leadId: deviceLead?.leadId,
                    })
                  }
                }}
              />
            </Box>
          )
        }
        return <DeviceLeadEmptyState key={`deviceLeads.vendor-empty-${_i}`} />
      })}
    </>
  )
}
