import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  Textarea,
} from '@rhythm/components'
import { useForm, useWatch } from 'react-hook-form'
import { useMutation } from 'react-query'
import * as yup from 'yup'

import { useApiContext } from '../../../../../context/ApiContext'
import { useTransmissionReportContext } from '../../../../../features/transmissionReports'
import { queryClient } from '../../../../../lib/api'
import NarrativeCard from '../NarrativeCard'

interface FormStateType {
  atrModeSwitch: string
  maxTime: string
  atrialBurden: string
  vhrEpisodes: string
  episodeComments: string
}

const schema = yup.object({
  atrModeSwitch: yup
    .string()
    .max(500, 'Maximum of 500 characters allowed.')
    .nullable()
    .optional(),
  maxTime: yup
    .string()
    .max(500, 'Maximum of 500 characters allowed.')
    .nullable()
    .optional(),
  atrialBurden: yup
    .string()
    .max(500, 'Maximum of 500 characters allowed.')
    .nullable()
    .optional(),
  vhrEpisodes: yup
    .string()
    .max(500, 'Maximum of 500 characters allowed.')
    .nullable()
    .optional(),
  episodeComments: yup
    .string()
    .max(2000, 'Maximum of 2000 characters allowed.')
    .nullable()
    .optional(),
})

const InClinicEpisodesCard = ({ reportId }: { reportId: number }) => {
  const { transmissionReport } = useTransmissionReportContext()

  const Api = useApiContext()

  const [hasInteracted, setHasInteracted] = useState(false)

  const methods = useForm<FormStateType>({
    defaultValues: (transmissionReport?.inClinicEpisode ?? {}) as FormStateType,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = methods

  // Watch all the form values for changes.
  const formValues = useWatch({ control })

  const { mutateAsync: updateInClinicEpisodeData, isLoading } = useMutation({
    mutationFn: async (newData: FormStateType) => {
      await Api.transmissionReportsControllerUpdateInClinicEpisode(
        reportId,
        newData,
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['transmissionReport', reportId])
    },
  })

  // Handling the submit by calling the mutation with the form data.
  const onSubmit = async (data: FormStateType) => {
    await updateInClinicEpisodeData(data)
  }

  // Trigger API call whenever formValues changes
  useEffect(() => {
    if (hasInteracted) {
      const debounce = setTimeout(() => {
        handleSubmit(onSubmit)()
      }, 500)

      return () => clearTimeout(debounce)
    }
  }, [formValues, handleSubmit, hasInteracted])

  const handleInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true)
    }
  }

  return (
    <NarrativeCard
      title="Episodes"
      icon="hospital-active"
      iconVariant={'info'}
      isLoading={isLoading}
    >
      <form onChange={handleInteraction}>
        <Flex direction={'column'} gap={6}>
          <SimpleGrid columns={3} gap={4}>
            <FormControl>
              <FormLabel>ATR Mode Switch</FormLabel>
              <Input
                {...register('atrModeSwitch')}
                placeholder={'ATR Mode Switch'}
              />
              {errors.atrModeSwitch && (
                <Text color="red.400">{errors.atrModeSwitch?.message}</Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Max Time</FormLabel>
              <Input {...register('maxTime')} placeholder={'Max Time'} />
              {errors.maxTime && (
                <Text color="red.400">{errors.maxTime?.message}</Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Atrial Burden</FormLabel>
              <Input
                {...register('atrialBurden')}
                placeholder={'Atrial Burden'}
              />
              {errors.atrialBurden && (
                <Text color="red.400">{errors.atrialBurden?.message}</Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>VHR Episodes</FormLabel>
              <Input
                {...register('vhrEpisodes')}
                placeholder={'VHR Episodes'}
              />
              {errors.vhrEpisodes && (
                <Text color="red.400">{errors.vhrEpisodes?.message}</Text>
              )}
            </FormControl>
          </SimpleGrid>
          <FormControl>
            <Textarea
              {...register('episodeComments')}
              variant={'default'}
              noOfLines={3}
              placeholder={'Episode Comments'}
            />
            {errors.episodeComments && (
              <Text color="red.400">{errors.episodeComments?.message}</Text>
            )}
          </FormControl>
        </Flex>
      </form>
    </NarrativeCard>
  )
}

export default InClinicEpisodesCard
